<template>
  <PageComponent>
    <SectionComponent id="about">
      <template v-slot:content>
        <p
          v-for="(paragraph, index) in about"
          :key="index"
          v-html="paragraph"
        ></p>
      </template>
    </SectionComponent>

    <SectionComponent
      id="skills"
      title="Skills"
      subtitle="Click a skill to filter experiences by it."
    >
      <template v-slot:content>
        <div class="skills-container">
          <div class="skill-stacks-container">
            <SkillStackComponent
              type="backend"
              :stack="backendSkills"
              :skillFilters="skillFilters"
              @skillClick="toggleFilterBySkill"
            />
            <SkillStackComponent
              type="frontend"
              :stack="frontendSkills"
              :skillFilters="skillFilters"
              @skillClick="toggleFilterBySkill"
            />
            <SkillStackComponent
              type="other"
              :stack="otherSkills"
              :skillFilters="skillFilters"
              @skillClick="toggleFilterBySkill"
            />
          </div>
        </div>
      </template>
    </SectionComponent>

    <SectionComponent id="experience" title="Experience">
      <template v-slot:content>
        <TimelineComponent :events="experience" :skillFilters="skillFilters" />
      </template>
    </SectionComponent>

    <SectionComponent id="education" title="Education">
      <template v-slot:content>
        <TimelineComponent :events="education" />
      </template>
    </SectionComponent>

    <button
      class="filter-reset-button"
      :class="canResetFilter ? '' : 'disable'"
      :disabled="!canResetFilter"
      @click="resetFilter"
    >
      <font-awesome-icon
        class="filter-reset-icon"
        :icon="['fa', 'filter-circle-xmark']"
      />
    </button>
  </PageComponent>
</template>

<script>
import "./HomePage.scss";
import {
  about,
  experience,
  education,
  backendSkills,
  frontendSkills,
  otherSkills,
} from "@/data/data.js";
import PageComponent from "@/components/pages/PageComponent.vue";
import SectionComponent from "@/components/section/SectionComponent.vue";
import SkillStackComponent from "@/components/skill-stack/SkillStackComponent.vue";
import TimelineComponent from "@/components/timeline/TimelineComponent.vue";

export default {
  name: "HomePage",

  components: {
    PageComponent,
    SectionComponent,
    SkillStackComponent,
    TimelineComponent,
  },

  methods: {
    toggleFilterBySkill(skill) {
      if (this.skillFilters.includes(skill)) {
        this.skillFilters = this.skillFilters.filter(
          (filteredSkill) => filteredSkill != skill
        );
      } else {
        this.skillFilters.push(skill);
      }
    },

    resetFilter() {
      this.skillFilters = [];
    },
  },

  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },

  computed: {
    canResetFilter() {
      return this.skillFilters.length != 0;
    },
  },

  data() {
    return {
      about,
      experience,
      education,
      backendSkills,
      frontendSkills,
      otherSkills,
      skillFilters: [],
    };
  },
};
</script>