<template>
  <section class="section-component">
    <h1 v-if="title" class="section-title">{{ title }}</h1>
    <span v-if="subtitle" class="section-subtitle"> {{ subtitle }} </span>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </section>
</template>

<script>
import "./SectionComponent.scss";

export default {
  name: "SectionComponent",

  props: {
    title: {
      type: String,
    },

    subtitle: {
      type: String,
    },
  },
};
</script>