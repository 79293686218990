export const about = [
    "I'm a full-stack software engineer with a Master's degree in Computer Science and expertise in backend engineering and API design. Having developed for various high-impact companies, ranging from <a href='https://www.systematic.com/' target='_blank'>a NATO partner</a> to <a href='https://www.microsoft.com/' target='_blank'>a major big tech corporation</a>, I've gained invaluable experience in software engineering, leadership and stakeholder management. My passion truly lies in building and delivering impactful solutions that empower individuals and organizations, and I do so with a cup-half-full attitude!",
    "Outside of building cool projects in my work and private time, I also enjoy following sports and esports, playing all sorts of games, hiking, and planning my next adventures.",
]

export const experience = [
    {
        date: "2023 - now",
        institution: "Systematic",
        title: "Senior Software Developer (Scrum Master)",
        summary: [
            "Scrum Master for an application security team",
            "Modernizing the security layer of a large Java/Scala/C# application to be compliant with modern security standards",
            "Implementing a cluster environment using Kubernetes, Helm and Docker",
            "Developing tooling in Bash/PowerShell"
        ],
        description:
            "Developing the C4ISR solution <b>SitaWare Headquarters</b> as part of the application security team. The team deals with modernizing the security layer to be compliant with modern security standards, utilizing Keycloak for identity- and access management. Our responsibilities also includes extending the cluster capabilities of the solution, utilizing Kubernetes, Helm and Docker to establish a robust, secure and performant cluster environment.",
        stack: [
            { name: "Java", type: "backend" },
            { name: "Scala", type: "backend" },
            { name: "C#", type: "backend" },
            { name: "PowerShell", type: "backend" },
            { name: "Bash", type: "backend" },
            { name: "Docker", type: "other" },
            { name: "Kubernetes", type: "other" },
            { name: "Keycloak", type: "other" },
            { name: "TeamCity", type: "other" },
            { name: "Microservices", type: "other" },
            { name: "REST", type: "other" },
            { name: "GIT", type: "other" },
        ],
    },
    {
        date: "2022 - 2023",
        institution: "Microsoft",
        title: "Software Engineer Student Worker",
        summary: [
            "Developed horizontal domain-logic, such as e-mail communication, data import and export, permissions, file-handling and more",
            "Designed, developed, and delivered multiple requested features within a cross-functional environment",
            "Delivered the highest number of work items on my team",
        ],
        description:
            "Developed the acclaimed ERP solution <b>Dynamics 365 Business Central</b> as part of the application-foundation team. The team was responsible for the horizontal domain-logic, such as e-mail communication, data import and export, permissions, file-handling, and more. I contributed to the team with the highest number of work items while developing partner-requested features and onboarding, developing and supervising an intern.",
        stack: [
            { name: "AL", type: "backend" },
            { name: "SQL", type: "backend" },
            { name: "GIT", type: "other" },
        ],
    },
    {
        date: "2020 - 2022",
        institution: "IT Minds",
        title: "Software Developer",
        summary: [
            "Tackled steep learning-curves developing solutions for clients of all sizes in various business domains",
            "Designed and developed complex systems and RESTful APIs using Java, Python, and Vue.js, usually with SQL databases",
            "Developed optimized solutions, such as when extending a Java eFX pricing engine to expand engine capabilities in a low-latency environment",
            "Gained experience with microservices, using technologies such as Docker and message brokers",
            "Clients included Danske Bank, Telia, DXC/SKAT, Chr. Hansen and more",
            "Was offered contract extensions in all my projects",
        ],
        description:
            "Consultant for a number of prominet clients such as Danske Bank, Telia, Chr. Hansen, and SKAT. I focused mainly on backend development in various programming languages, spanning a wide range of tech stacks from small-scale start-up solutions to large-scale, latency-efficient environments. I thrived in tackling steep learning curves and jumping straight into dynamic environments - this enabled me to take control of projects behind on deadlines and deliver high-quality solutions.",
        stack: [
            { name: "Java", type: "backend" },
            { name: "Scala", type: "backend" },
            { name: "Python", type: "backend" },
            { name: "SQL", type: "backend" },
            { name: "Vue", type: "frontend" },
            { name: "TypeScript", type: "frontend" },
            { name: "JavaScript", type: "frontend" },
            { name: "HTML", type: "frontend" },
            { name: "CSS", type: "frontend" },
            { name: "Docker", type: "other" },
            { name: "RabbitMQ", type: "other" },
            { name: "Kafka", type: "other" },
            { name: "Microservices", type: "other" },
            { name: "REST", type: "other" },
            { name: "GIT", type: "other" },
        ],
    },
    {
        date: "2019",
        institution: "Danske Bank",
        title: "IT Student",
        summary: ["Developed a SQL database restoration tool in C#"],
        description:
            "Developed a standalone database restoration tool for internal use. The solution utilized WinForms framework to provide a UI for developers when restoring databases from stored .bak files.",
        stack: [
            { name: "C#", type: "backend" },
            { name: "SQL", type: "backend" },
            { name: "GIT", type: "other" },
        ],
    },
    {
        date: "2014",   
        institution: "Danish Defence",
        title: "Conscript (Conscript Squad Leader)",
        summary: ["Assistant Squad Leader", "Grade: A2"],
        description:
            "Voluntary conscript with the assigned role of assistant squad leader in charge of managing and leading a squad of 11 conscripts. I focused on leading and motivating the team while facilitating communication between the team and leadership.",
    },
];
  
export const education = [
    {
        date: "2024",
        institution: "Scrum Alliance",
        title: "Certified ScrumMaster",
        description:
        "Acquired in-depth knowledge and practical tools to effectively lead Scrum teams, foster team well-being, and enhance company value through agile methodologies.",
    },
    {
        date: "2021 - 2023",
        institution: "University of Copenhagen",
        title: "MSc in Computer Science",
        summary: [
        "Software engineering track",
        "Explored advanced topics such as software engineering and architecture, blockchain, machine learning, and human-centered design",
        "Gained proficiency in project management, entrepeneurship, and innovation",
        "Wrote Master's thesis in blockchain and DCR graphs",
        "Graduation was followed by a PHD offer within process mining",
        ],
        description:
        "Expanded my knowledge in algorithms, advanced programming, and IT security. Studying on the software engineering track, I delved into intriguing areas such as blockchain technology, software architecture. Additionally, I researched machine learning applications and implications, and acquired valuable skills in project management, innovation, and entrepreneurship. I wrote my thesis on implementing a blockchain with native support for DCR Graph smart contracts, which received the maximum grade and was followed by a Ph. D. offer.",
    },
    {
        date: "2017",
        institution: "Systematic",
        title: "Scrum Course Certificate",
        description:
        "Learned about Scrum and team management in agile environments. Throughout the duration of the course, I actively participated in various team-based exercises and tackled diverse case studies, improving my skills in effectively organizing and guiding teams towards accomplishing shared objectives.",
    },
    {
        date: "2016 - 2020",
        title: "BSc in Computer Science",
        institution: "University of Copenhagen",
        summary: [
            "Explored topics such as IT security, computer systems and machine learning",
            "Gained proficiency in IT project leadership",
            "Wrote Bachelor's thesis in IT security and DDoS attacks",
        ],
        description:
        "Gained extensive knowledge of programming and advanced computer science theory. I wrote my thesis on analyzing the efficacy of the DoS attack Slowloris and its capabilities in various environments, and how Apache HTTP Server as a webserver implements security techniques to mitigate such attacks.",
    },
];
  
const skills = [...experience, ...education].flatMap(item => item.stack || []);
export const backendSkills = new Set(skills.filter(skill => skill.type == "backend").map(skill => skill.name));
export const frontendSkills = new Set (skills.filter(skill => skill.type == "frontend").map(skill => skill.name));
export const otherSkills = new Set(skills.filter(skill => skill.type == "other").map(skill => skill.name));
  